<template>
  <v-container>
  <div class="bg-gray-50 px-8" style="width:400px;" align="center">
    <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" v-model="valor">
      <div v-show="showScanConfirmation" class="scan-confirmation">
        {{result}}
        <!-- <img :src="$withBase('https://www.google.com/imgres?imgurl=https%3A%2F%2Fpng.pngitem.com%2Fpimgs%2Fs%2F379-3790122_thumb-image-check-verde-icon-png-transparent-png.png&imgrefurl=https%3A%2F%2Fwww.pngitem.com%2Fmiddle%2FibRixbw_blue-check-pd-visto-svg-hd-png-download%2F&tbnid=WqW3Bj-B4QQyJM&vet=12ahUKEwjZjKjwq-v6AhWkm4QIHWIkD3sQMygDegUIARDGAQ..i&docid=QXfWZ-QIOrRIrM&w=367&h=280&q=visto%20svg&client=firefox-b-d&ved=2ahUKEwjZjKjwq-v6AhWkm4QIHWIkD3sQMygDegUIARDGAQ')" alt="Checkmark" width="128px" /> -->

      </div>
    </qrcode-stream>
    <!-- <qrcode-drop-zone></qrcode-drop-zone>
    <qrcode-capture></qrcode-capture> -->
    <!-- <input
    v-model="valor"
    type="text"
      /> -->
  <!-- <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">

      <div v-show="showScanConfirmation" class="scan-confirmation">

        <img :src="$withBase('/checkmark.svg')" alt="Checkmark" width="128px" />

      </div>

    </qrcode-stream> -->
  </div>
    
  </v-container>
</template>

<script>
import { QrcodeStream } from 'vue3-qrcode-reader'
// import { QrcodeDropZone } from 'vue3-qrcode-reader'
// import { QrcodeCapture } from 'vue3-qrcode-reader'

export default {
  name: 'HelloWorld',
  components:  {
    QrcodeStream
    // ,QrcodeDropZone,
    // QrcodeCapture
  },
  data: () => ({
      camera: 'auto',
      result: null,
      showScanConfirmation: false,
    
  }),
  methods: {



    async onInit (promise) {

      try {

        await promise

      } catch (e) {

        console.error(e)

      } finally {

        this.showScanConfirmation = this.camera === "off"

      }

    },



    async onDecode (content) {

      this.result = content



      this.pause()

      await this.timeout(500)

      this.unpause()

    },



    unpause () {

      this.camera = 'auto'

    },



    pause () {

      this.camera = 'off'

    },



    timeout (ms) {

      return new Promise(resolve => {

        window.setTimeout(resolve, ms)

      })

    }

  }
}
</script>
